import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
 * {
     box-sizing: border-box;
     padding: 0;
     margin: 0;
 }

 :root {
     font-size: 16px;
 }

 header nav ul a {
     text-decoration: none;
     color: #333;
 }

 header nav ul a:hover,
 footer ul a:hover {
     border-bottom: 3px solid #219EBC;
 }

 .button {
     background: #fb8524;
     padding: 12px 22px;
     border-radius: 8px;
     color: #fff;
     text-transform: uppercase;
     display: inline-block;
     border: none;
 }

 .button:hover {
     background: #219EBC;
 }

 main {
     margin-top: 100px;
 }
 @media (max-width: 767px) {
     main {
         margin-top: 110px;
     }
 }
`;

export default GlobalStyles