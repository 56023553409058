import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
html {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin: 0;
    padding-bottom: 1rem;
}

p {
    padding-bottom: .5rem;
}

.textCenter {
    text-align: center;
}
`;

export default Typography