/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "normalize.css"
import GlobalStyles from '../styles/GlobalStyles';
import Typography from "../styles/Typography";
import Header from "./header"
import Footer from './footer';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: sanityImageAsset(originalFilename: {eq: "logo.png"}) {
        fluid (maxWidth: 100) {
          ...GatsbySanityImageFluid
          }
        }
      }
  `)

  return (
    <div>
      <GlobalStyles/>
      <Typography/>
      <Header data={data} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
