import React, { useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import Img from 'gatsby-image';
import { AlternateLinksContext } from "./wrapWithI18nProvider";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import HamburgerMenu from "./hamburger";

const HeaderStyles = styled.header`
  background: white;
  padding: 30px 40px;
  font-size: 1.2rem;
  box-shadow: 0px 0px 5px #808080;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  @media (max-width: 768px) {
    padding: 20px; 
    transition: 500ms;
    ${ (props) => props.scroll && `
        padding: 30px 20px;
      `}
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 736px) {
      gap: 15px;
      flex-wrap: wrap;
      padding-top: .5rem;
      position: static;
    .logo {
      top: 50%;
      transition: 800ms;
      ${ (props) => props.scroll && `
        top: 40%;
      `}
    }
    }

  }
 }
`;

const Logo = styled.div `
    width: 130px;
    position: absolute;
    top: -50%;
    transition: all .8s;
    @media (max-width: 425px) {
      width: 90px;
    }

 &.small {
     width: 90px;
     @media (max-width: 568px) {
       width: 70px;
     }
 }
`

const DesktopMenu = styled.ul`
   list-style: none;
    display: flex;
    flex-basis: 60%;
    justify-content: space-evenly;
    margin-left: 10%;
    @media (min-width: 768px) and (max-width: 1024px) {
      margin-left: 15%;
      font-size: 1rem;
    }
    @media (max-width: 568px) {
      display: none;
    }
`;

const Donate = styled.div`
@media (max-width: 767px) {
  display: none;
}
  .button {
    text-decoration: none;
  @media (max-width: 767px) {
    display: none;
  }
}
`;

const LanguageButtonStyles = styled.div`
    text-align: right;
    font-size: .9rem;
    padding-bottom: 1rem;

    a {
      color: #219EBC;
    }

    a:hover {
      color: #fb8524;
    }

    p {
      display: inline-block;
      margin-right: 5px;
      padding-bottom: 0;
    }
`;


const Header = ( {data} ) => {
  const alternateLinks = useContext(AlternateLinksContext)
  const { t, i18n } = useTranslation("common")

  const [logo, setLogo] = useState(false);

  const changeLogo = () => {
    if (window.scrollY >= 150) {
      setLogo(true)
    } else {
      setLogo(false)
    }
  } 

  useEffect(() => {
    window.addEventListener('scroll', changeLogo);
    return function cleanup() {
      window.removeEventListener('scroll', changeLogo);
    }
  }, []);


  return (
    <HeaderStyles scroll={logo}>
          <LanguageButtonStyles className="language">
            <p>{t("choose")}</p>
            {alternateLinks &&
              alternateLinks
                .map((link, i) => [
                  i > 0 && " or ",
                  <Link
                    key={i}
                    to={link.path}
                    hrefLang={link.language}
                  >
                    {t(link.language)}
                  </Link>,
                ])}
                {/* .filter(link => link.language !== i18n.language) */}
                {/* {alternateLinks.map(link => console.log(link))} */}
          </LanguageButtonStyles>
      <nav>
        <Link to={"/" + i18n.language}>
          <Logo className={logo ? 'logo small' : 'logo'}>
            <Img fluid={data.logo.fluid}  alt={data.site.siteMetadata.title} />
          </Logo>
        </Link>
        <HamburgerMenu />
        <DesktopMenu>
          <li><Link to={"/" + i18n.language + "/about"}>About</Link></li>
          <li><Link to={"/"+ i18n.language + "/our-work"}>Our Work</Link></li>
          <li><Link to={"/"+ i18n.language + "/get-involved"}>Get Involved</Link></li>
          <li><Link to={"/"+ i18n.language + "/news-and-updates"}>News and Updates</Link></li>
        </DesktopMenu>
        <Donate>
          <Link to={"/"+ i18n.language + "/get-involved"} className="button">Donate</Link>
        </Donate>
      </nav>
    </HeaderStyles>
  )
}

export default Header
