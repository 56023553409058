import React from "react"
import { StaticQuery, Link, graphql } from "gatsby";
import Img from 'gatsby-image';
import { AlternateLinksContext } from "./wrapWithI18nProvider";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons/lib";
import * as FaIcons from 'react-icons/fa';
import styled from "styled-components";

const FooterStyles = styled.footer`
    background: #14213D;
    color: #fff;
    padding: 50px 100px;
    h3 {
        font-size: 2rem;
        display: inline-block;
    }

    @media (max-width: 767px) {
        padding: 50px 25px;

        .contactInfo {
            @media (max-width: 767px) {
                padding-left: 10px;
            }
        }
    }

    .footerMain {
     display: flex;
     justify-content: space-between;
     padding-bottom: 4rem;
     @media (max-width: 767px) {
         flex-direction: column;
     }
    }

    .copyright {
        font-size: 13px;
        padding-left: 10px;
        @media (max-width: 767px) {
            font-size: 10px;
        }
        a:hover {
            color: #219EBC;
        }
    }

    ul {
        list-style: none;
        padding-top: 10px;
        padding-left: 10px;
    }

    li {
        padding-bottom: 15px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    .contactInfo {
        text-align: right;
        @media (max-width: 425px) {
            text-align: left;
        }
    }

    .contactInfo h3::after {
        content: '';
        display: block;
        height: 5px;
        background: #219EBC;
    }

    .footerLogo {
        display: block;
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
    }
`;

const SocialStyles = styled.div`
    display: flex;
    justify-content: flex-end;
    a {
        padding-left: 20px;
    }
    a:hover {
        color: #FFCE7E;
    }
    @media (max-width: 425px) {
        justify-content: flex-start;
    }
`;

const AddressStyle = styled.address`
    font-style: normal;
    font-size: .9rem;
    padding-bottom: 1rem;
    span {
        font-weight: 600;
        padding-bottom: .4rem;
        display: block;
    }
    .address {
        padding-bottom: .8rem;
        
    }
`;

const Footer = ({data}) => {
    const { i18n } = useTranslation("common");
    return(
        <FooterStyles>
            <div className="footerMain">
                <div>
                    <Link to={"/" + i18n.language} className="footerLogo">
                        {data.setting.nodes.map((node, index) => 
                            <Img key={index} fluid={node.logo.asset.fluid} alt={node.title} />
                        )}
                    </Link>
                    <ul>
                        <li><Link to={"/" + i18n.language + "/about"}>About</Link></li>
                        <li><Link to={"/"+ i18n.language + "/our-work"}>Our Work</Link></li>
                        <li><Link to={"/"+ i18n.language + "/get-involved"}>Get Involved</Link></li>
                        <li><Link to={"/"+ i18n.language + "/news-and-updates"}>News and Updates</Link></li>
                    </ul>
                </div>
                <div className="contactInfo">
                    <h3>Contact Us</h3>
                    <AddressStyle>
                        <span>Tarlac:</span>
                        <div className="address">
                                Block 8 Lot 91 Phase 2 <br/>
                                Sitio Christian, Barangay Cristo Rey <br/>
                                Capas, Tarlac, 2315 Philippines
                        </div>
                        <span>Manila:</span>
                        <div className="address">
                                161 Mother Ignacia Avenue<br/>
                                South Triangle, Quezon City, Philippines
                        </div>
                    </AddressStyle>
                    <SocialStyles>
                        <IconContext.Provider value={{size: "1.5rem"}}>
                            <a href="https://www.facebook.com/aapafinc">
                                <FaIcons.FaFacebookF />
                            </a>
                            <a href="https://twitter.com/aapafinc">
                                <FaIcons.FaTwitter />
                            </a>
                            <a href="https://www.instagram.com/aapafinc/">
                                <FaIcons.FaInstagram />
                            </a>
                        </IconContext.Provider>
                    </SocialStyles>
                </div>
            </div>
            <div className="copyright">
                © Copyright {new Date().getFullYear()} Ako Aeta, Pilipino Ako Foundation Inc. by <a href="https://eloisaborromeo.com" target="_blank" rel="noopener noreferrer">EB</a>
            </div>
        </FooterStyles>
    )
}

export default function FooterQuery(){
    return (
      <StaticQuery
        query={graphql`
          query {
            setting: allSanityGeneral {
              nodes {
                logo {
                  asset {
                    fluid (maxWidth: 100) {
                    ...GatsbySanityImageFluid
                    } 
                  }
                }
                title
              }
            }
          }
        `}
        render={data => <Footer key={"footer"} data={data} />}
      />
    )
  }