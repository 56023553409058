import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import * as FaIcons from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { IconContext } from 'react-icons/lib';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const Mobile = styled.div`
    display: none;
    .nav-menu {
        display: none;
    }
 @media (max-width: 568px) {
     display: block;
    .nav-menu {
        display: flex;
        list-style: none;
        flex-direction: column;
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        gap: 15px;
        position: absolute;
        top: 0;
        right: -100%;
        background: white;
        z-index: 9;
        width: 100vw;
        height: 100vh;
        transition: 700ms;
    }
    .nav-menu.active {
        right: 0;
        transition: 650ms;
        }
    }

    li {
        font-size: 1.8rem;
    }

    li:first-child {
        font-size: 1em;
        text-align: right;
    }
    li:last-child {
        padding-top: .5em;
        font-size: 1.5em;
    }   
`;

const Burger = styled.div`
    display: none;
    @media (max-width: 568px) {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        svg {
            margin-right: 10px;
        }
    }
`

export default function HamburgerMenu() {
    const { i18n } = useTranslation("common")
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    return (
        <IconContext.Provider value={{ size: "1.8em" }}>
            <Mobile>
                <Burger onClick={showSidebar}>
                    <FaIcons.FaBars />Menu
                </Burger>
                <ul className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <li>
                        <GrClose onClick={showSidebar} />
                    </li>
                    <li><Link to={"/" + i18n.language + "/about"}>About</Link></li>
                    <li><Link to={"/"+ i18n.language + "/our-work"}>Our Work</Link></li>
                    <li><Link to={"/"+ i18n.language + "/get-involved"}>Get Involved</Link></li>
                    <li><Link to={"/"+ i18n.language + "/news-and-updates"}>News and Updates</Link></li>
                    <li><Link to={"/"+ i18n.language + "/get-involved"} className="button">Donate</Link></li>
                </ul>
            </Mobile>
        </IconContext.Provider>
    )
}